/* Base Styles */
.App {
  text-align: center;
  background-color: #2E2E2E; /* Charcoal background for the main app */
  color: #F5F5F5; /* Light gray text for high contrast */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Modern, readable font */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #38618c; /* Charcoal background for header */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #F5F5F5; /* Light gray text */
}

.App-link {
  color: #FFB400; /* Mustard yellow for links */
  text-decoration: none;
  transition: color 0.3s ease;
}

.App-link:hover {
  color: #FF6F61; /* Coral on hover */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* AR View Container */
.ar-view-container {
  width: 100%;
  display: flex;
  flex-direction: column; /* Vertically stack AR content */
  justify-content: center;
  align-items: center;
  background-color: #2E2E2E; /* Charcoal background */
  padding: 20px;
  position: relative;
  box-sizing: border-box; /* Ensure consistent sizing */
}

.ar-view-wrapper {
  width: 100%;
  max-width: 1200px; /* Limit width on larger screens */
  aspect-ratio: 16 / 9; /* Maintain responsive 16:9 aspect ratio */
  border: 2px solid #008080; /* Teal border for AR display */
  border-radius: 10px;
  overflow: hidden;
  background-color: #1f1f1f; /* Slight contrast for AR area */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5); /* Enhanced shadow */
}

.ar-placeholder-text {
  font-size: 18px;
  color: #F5F5F5; /* Light gray text */
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}

.metadata-box {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.85); /* Semi-transparent charcoal */
  color: #F5F5F5; /* Light gray text */
  padding: 20px;
  border-radius: 10px;
  font-size: 14px;
  max-width: 80%;
  text-align: center;
  overflow-y: auto;
  max-height: 40vh; /* Prevent metadata overflow */
  width: 100%;
  box-sizing: border-box;
}

.metadata-box p {
  margin: 10px 0;
}

.ar-fallback-message {
  color: #F5F5F5; /* Light gray text */
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0.85);
  padding: 20px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  text-align: center;
}

/* Desktop View - Full AR Scene */
@media (min-width: 1024px) {
  .ar-view-container {
    width: 100%;
    height: auto;
    padding: 40px; /* Add padding for larger screens */
  }

  .ar-view-wrapper {
    max-width: 1200px; /* Larger container width for desktops */
    aspect-ratio: 16 / 9; /* Ensure AR view scales correctly */
  }

  .metadata-box {
    font-size: 16px;
    max-width: 60%; /* Expand metadata width on larger screens */
    padding: 20px;
  }

  a-image {
    scale: 3 3 3; /* Larger asset scale for desktop view */
  }
}

.asset-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr)); /* Default grid for desktop */
  gap: 24px;
  padding: 40px;
  max-width: 1400px;
  margin: 0 auto;
}

.asset-card {
  background-color: #333333; /* Dark gray for asset cards */
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  height: 380px;
  display: flex;
  flex-direction: column;
}

.asset-card:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.6);
}

.asset-card img {
  width: 100%;
  height: 70%;
  object-fit: cover;
}

.asset-card .asset-info {
  padding: 15px;
  text-align: center;
  background-color: #444444; /* Slightly lighter dark background */
  color: #F5F5F5; /* Light gray text */
}

.asset-card .asset-info h5 {
  font-size: 18px;
  font-weight: bold;
  color: #FFB400; /* Mustard yellow for titles */
}

.asset-card .asset-info p {
  font-size: 14px;
  color: #BBBBBB; /* Medium gray for descriptions */
}


/* General adjustments for AR View container */
.ar-view-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ar-view-wrapper {
  width: 100%;
  max-width: 90%;
  aspect-ratio: 4 / 3; /* Adjusted for smaller screens */
  border-radius: 8px;
  overflow: hidden;
}

/* Metadata Panel Styling */
.metadata-box {
  font-size: 12px;
  padding: 10px;
  max-height: 40vh; /* Prevent overflow on smaller screens */
  overflow-y: auto;
}

/* AR Toggle Button */
.view-ar-button {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px 24px;
  font-size: 14px;
  background-color: #008080;
  color: #F5F5F5;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-ar-button:hover {
  background-color: #006666;
}

/* Mobile-specific styles */
@media screen and (max-width: 450px) {
  .ar-view-wrapper {
    width: 100%;
    max-width: 100%;
    aspect-ratio: 1 / 1; /* Square aspect ratio for compact screens */
  }

  .metadata-box {
    font-size: 10px; /* Smaller text for metadata */
  }

  .view-ar-button {
    padding: 10px 20px;
    font-size: 12px; /* Slightly smaller font for mobile */
  }

  .asset-card img {
    height: 80px;
    width: 100%;
    object-fit: contain;
  }

  .asset-card .asset-info {
    font-size: 10px;
  }
}


/* Add this CSS */
.connect-wallet .copy-icon {
  font-size: 14px;
  color: #555;
  margin-left: 8px;
  cursor: pointer;
}

.connect-wallet .copy-icon:hover {
  color: #007bff;
}

.connect-wallet .ada-handle-section {
  margin-top: 10px;
}

.connect-wallet .wallet-details p {
  margin: 5px 0;
}

.address-container {
  position: relative;
  display: inline-block;
}

.address-text {
  font-size: 12px; /* Default smaller size */
  white-space: nowrap; /* Prevent line breaks */
  overflow: hidden;
  text-overflow: ellipsis; /* Ellipsis for truncated text */
  max-width: 200px; /* Set a width to limit truncation */
  display: inline-block;
  cursor: pointer;
}

.address-container:hover .full-address {
  visibility: visible;
  opacity: 1;
}

.full-address {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  background-color: #000; /* Background for tooltip */
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px;
  font-size: 12px; /* Tooltip font size */
  white-space: nowrap; /* Prevent tooltip line breaks */
  z-index: 1;
  bottom: 125%; /* Position above the truncated text */
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.3s;
}


/* Wrapper for Connect Wallet */
.connect-wallet {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

/* Connect Wallet Button */
.connect-button {
  padding: 12px 24px;
  font-size: 16px;
  background-color: #008080; /* Teal background */
  color: #F5F5F5; /* Light gray text */
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.connect-button:hover {
  background-color: #006666; /* Darker teal on hover */
  transform: scale(1.02); /* Slight zoom on hover */
}

/* Dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  width: 380px; /* Ensure consistent dropdown width */
}

/* Dropdown Content */
.dropdown-content {
  display: none; /* Initially hidden */
  position: absolute;
  top: 100%; /* Place it below the button */
  left: 0; /* Adjusted to align with parent container */
  background-color: #444444; /* Dark gray background */
  width: 100%; /* Match dropdown width to the parent container */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.5);
  z-index: 1;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;
  border-radius: 8px;
  padding: 15px; /* Add padding inside the dropdown */
  overflow: hidden; /* Prevent content from spilling out */
  box-sizing: border-box; /* Include padding and border in width and height */
}

/* Optional: Ensure child elements don't exceed container */
.dropdown-content * {
  max-width: 100%;
  box-sizing: inherit; /* Inherit box-sizing from parent */
}

/* Example: If using flex layout inside dropdown */
.dropdown-content {
  display: flex;
  flex-direction: column;
  align-items: stretch; /* Ensure children stretch to fill the container */
}

/* Show dropdown on hover or click */
.dropdown:hover .dropdown-content,
.dropdown:focus-within .dropdown-content {
  display: block;
  opacity: 1;
  visibility: visible;
  transform: translateY(0); /* Slide into place */
}


/* Wallet Item Buttons */
.wallet-item {
  padding: 15px 20px; /* Add spacing inside the buttons */
  margin-bottom: 10px; /* Add margin between buttons */
  background-color: #555555; /* Slightly lighter dark background */
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px; /* Rounded corners */
  text-align: left;
  width: 100%; /* Full width of the dropdown */
  box-sizing: border-box; /* Include padding in width */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.wallet-item:hover {
  background-color: #666666; /* Even lighter on hover */
  transform: scale(1.02); /* Slight zoom on hover */
}

/* Wallet Icon */
.wallet-icon {
  border-radius: 50%;
  width: 32px;
  height: 32px;
}

/* Connected Wallet Info */
.connected-wallet {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #FFB400; /* Mustard yellow for connected wallet info */
  font-size: 14px;
  font-weight: bold;
}

.connected-wallet img {
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

/* Wallet Details */
.wallet-details {
  margin-top: 15px;
  padding: 10px;
  background-color: #333333; /* Darker background for wallet details */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Subtle shadow */
}

.wallet-details span {
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  color: #F5F5F5; /* Light gray text */
}

/* Disconnect Button */
.disconnect-button {
  padding: 10px;
  font-size: 14px;
  background-color: #FF6347; /* Tomato color for disconnect */
  color: #F5F5F5;
  border: none;
  cursor: pointer;
  border-radius: 8px; /* Rounded corners */
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-top: 10px;
  width: 100%; /* Full width of dropdown */
  box-sizing: border-box; /* Include padding in width */
}

.disconnect-button:hover {
  background-color: #D1503B; /* Darker red on hover */
  transform: scale(1.05); /* Slight hover scaling */
}


/* Wallet Transaction Component */
.wallet-tx-container {
  background-color: #1f1f1f; /* Dark background for transaction container */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  margin-top: 20px;
  max-width: 600px;
  width: 100%;
}

.wallet-tx-container h3 {
  text-align: center;
  margin-bottom: 20px;
  color: #FFB400; /* Mustard yellow for headers */
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #F5F5F5; /* Light gray text */
}

.form-control {
  width: 100%;
  padding: 10px 14px;
  border: 1px solid #555555; /* Dark gray border */
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #333333; /* Dark background for inputs */
  color: #F5F5F5; /* Light gray text */
  transition: border-color 0.3s ease;
}

.form-control:focus {
  border-color: #FFB400; /* Mustard yellow border on focus */
  outline: none;
}

.btn-primary {
  background-color: #008080; /* Teal background */
  color: #F5F5F5; /* Light gray text */
}

.btn-primary:hover {
  background-color: #006666; /* Darker teal on hover */
}

.btn-secondary {
  background-color: #FF6F61; /* Coral background */
  color: #F5F5F5; /* Light gray text */
}

.btn-secondary:hover {
  background-color: #e55b4e; /* Darker coral on hover */
}

.btn {
  padding: 12px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.w-100 {
  width: 100%;
}

.mt-2 {
  margin-top: 10px;
}

.mt-3 {
  margin-top: 20px;
}

.mt-4 {
  margin-top: 30px;
}

.error {
  background-color: #E74C3C; /* Scarlet red for errors */
  color: #F5F5F5; /* Light gray text */
  padding: 15px;
  border-radius: 5px;
  margin-top: 20px;
  text-align: left;
}

.success {
  background-color: #2ECC71; /* Emerald green for success */
  color: #F5F5F5; /* Light gray text */
  padding: 15px;
  border-radius: 5px;
  margin-top: 20px;
  text-align: left;
}

.utxos-section {
  width: 100%;
  max-width: 800px;
  margin: 40px auto;
  background-color: #1f1f1f; /* Dark background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
}

.utxos-section h3 {
  margin-bottom: 20px;
  color: #FFB400; /* Mustard yellow for headers */
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  border: 1px solid #555555; /* Dark gray borders */
  text-align: left;
  padding: 10px;
  color: #F5F5F5; /* Light gray text */
}

.table th {
  background-color: #444444; /* Slightly lighter dark background */
}

.text-break {
  word-break: break-all;
}

.btn-select, .btn-ar {
  padding: 8px 12px;
  font-size: 14px;
  background-color: #FFB400; /* Mustard yellow for action buttons */
  color: #2E2E2E; /* Dark text */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-select:hover, .btn-ar:hover {
  background-color: #e0a800; /* Darker mustard yellow on hover */
}

/* AR View Specific Styles */
.ar-view-container h3 {
  color: #FFB400; /* Mustard yellow for AR headers */
}

.ar-fallback-message {
  color: #F5F5F5; /* Light gray text */
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0.85);
  padding: 20px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  text-align: center;
}

/* Responsive Typography */
@media screen and (max-width: 760px) {
  .App-header {
    font-size: calc(8px + 1.5vmin);
  }

  .ar-view-wrapper {
    aspect-ratio: 4 / 3; /* Adjust aspect ratio for smaller screens */
  }

  .metadata-box {
    font-size: 12px; /* Slightly larger for readability */
  }

  .asset-card .asset-info h5 {
    font-size: 16px;
  }

  .asset-card .asset-info p {
    font-size: 12px;
  }

  .btn {
    font-size: 14px;
    padding: 10px 16px;
  }
}

/* Modal Overlay - Semi-transparent dark background */
.modal-overlay {
  background-color: rgba(0, 0, 0, 0.7); /* Dark background with opacity */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050; /* Ensure it's on top */
}

/* Modal Content - Centered and with rounded corners */
.modal-content {
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 600px; /* Set max-width to 600px for a more compact look */
  transition: all 0.3s ease-in-out;
  position: relative; /* Ensure the close button is correctly positioned */
}

/* Modal Header */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 1rem;
}

.modal-title {
  font-size: 1.3rem;
  font-weight: bold;
}

/* Close button (X) */
.btn-close {
  background: none;
  border: none;
  font-size: 1.2rem;
  color: #aaa;
  cursor: pointer;
  position: absolute; /* Positioning it absolutely in the top-right corner */
  top: 10px;
  right: 10px;
}

.btn-close:hover {
  color: #000;
}

/* Modal Body */
.modal-body {
  padding-top: 1rem;
}

/* Fixed modal styling */
.fixed-modal {
  max-width: 600px; /* Fixed width */
  width: 100%;
  max-height: 80vh; /* Prevents modal from growing too tall */
  height: auto;
  overflow-y: auto; /* Makes the body scrollable if content overflows */
  border-radius: 10px;
  background-color: white;
  padding: 20px;
}

/* Custom modal overlay */
.modal-overlay {
  background-color: rgba(0, 0, 0, 0.5); /* Dark background */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Button styles */
.btn {
  font-weight: bold;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  border: none;
  margin: 5px;
  transition: all 0.3s ease-in-out;
}

.btn:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.btn-success:hover {
  background-color: #28a745;
}

.btn-outline-success:hover {
  background-color: #28a745;
  color: #fff;
}

.btn-primary:hover {
  background-color: #007bff;
}

.btn-outline-primary:hover {
  background-color: #007bff;
  color: #fff;
}

.btn-warning:hover {
  background-color: #ffc107;
}

.btn-outline-warning:hover {
  background-color: #ffc107;
  color: #fff;
}

.btn-info:hover {
  background-color: #17a2b8;
}

.btn-outline-info:hover {
  background-color: #17a2b8;
  color: #fff;
}

.btn-danger:hover {
  background-color: #dc3545;
}

.btn-outline-danger:hover {
  background-color: #dc3545;
  color: #fff;
}

/* Dropzone styling */
.dropzone {
  border: 2px dashed #007bff;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 15px;
  transition: all 0.3s ease-in-out;
}

.dropzone p {
  margin: 0;
  font-size: 16px;
  color: #555;
}

.dropzone:hover {
  border-color: #0056b3;
  background-color: #f1f1f1;
}

/* Flash effect on drag */
.flash {
  animation: flash-animation 0.5s alternate infinite;
}

@keyframes flash-animation {
  0% {
    border-color: #ff8c00;
    background-color: #fff3cd;
  }
  100% {
    border-color: #ff4500;
    background-color: #ffebcd;
  }
}

/* Image thumbnail preview */
.image-preview {
  margin-top: 10px;
  position: relative;
}

.thumbnail {
  max-width: 100%;
  max-height: 200px;
  object-fit: cover;
  border-radius: 5px;
}

.remove-image-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 5px 8px;
  font-size: 12px;
  cursor: pointer;
}

.remove-image-btn:hover {
  background-color: #c82333;
}



/* Table Styles */
.table {
  width: 100%;
  border-collapse: collapse;
  background-color: #1f1f1f; /* Dark background */
  border-radius: 8px;
  overflow: hidden; /* Ensure rounded corners apply to entire table */
}

.table th,
.table td {
  padding: 10px;
  text-align: left;
  color: #F5F5F5; /* Light gray text */
  border: 1px solid #555555; /* Dark gray borders */
}

.table th {
  background-color: #444444; /* Slightly lighter dark background */
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
}

.table td {
  background-color: #333333; /* Dark gray for cells */
}

.table-hover tbody tr:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #2E2E2E; /* Slightly lighter background on hover */
}

/* Asset Image Styling */
img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Slight shadow for depth */
}

/* Button Styling */
.btn {
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 4px;
  font-weight: bold;
  transition: all 0.3s ease;
  cursor: pointer;
  border: none;
}

.btn:hover {
  transform: scale(1.05);
}

.btn-outline-success {
  background-color: transparent;
  color: #28a745; /* Green */
  border: 2px solid #28a745;
}

.btn-outline-success:hover {
  background-color: #28a745; /* Solid green on hover */
  color: white;
}

.btn-outline-primary {
  background-color: transparent;
  color: #007bff; /* Blue */
  border: 2px solid #007bff;
}

.btn-outline-primary:hover {
  background-color: #007bff; /* Solid blue on hover */
  color: white;
}

/* Typography */
.fw-bold {
  font-weight: bold;
}

.text-muted {
  color: #BBBBBB; /* Muted gray for secondary text */
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Responsive Adjustments */
@media screen and (max-width: 760px) {
  .table {
    font-size: 14px;
  }

  img {
    width: 40px;
    height: 40px;
  }

  .btn {
    font-size: 12px;
    padding: 6px 10px;
  }
}
/* Tx Puurrtal */
.fixed-top-button-container {
  position: fixed;        /* Fixes the button at the top */
  top: 15px;              /* Adjust the distance from the top */
  left: 65%;              /* Centers the button horizontally */
  transform: translateX(-50%);  /* Adjust for true centering */
  z-index: 9999;          /* Ensures the button stays on top of other elements */
  width: 100%;
  display: flex;
  justify-content: center;
}


/* src/component/WalletUTXOs.css */

.scroll-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  width: 100%;
}

.scroll-container::-webkit-scrollbar {
  height: 8px;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.scroll-item {
  flex: 0 0 100%;
  padding: 10px;
}

/* App.css */
.navbar-container {
  width: 100%;
  position: relative;
}

.banner {
  width: 100%;
  height: 250px;  /* Adjust as needed */
  background-color: #000;  /* Add a fallback color in case the image doesn't load */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.navbar {
  position: absolute;
  top: 20px;
  left: 10px;
  right: 10px;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
